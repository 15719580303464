import { makeStyles, Theme } from "@material-ui/core"
import { ChatBubbleOutline, EmailOutlined, Phone } from "@material-ui/icons"
import { SpeedDial, SpeedDialAction } from "@material-ui/lab"
import React, { useState } from "react"
import Img from "gatsby-image"
import useContactContent from "../hooks/use-contact-content"
import useImagesContent from "../hooks/use-images-content"

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 380,
    transform: "translateZ(0px)",
    flexGrow: 1,
  },
  speedDial: {
    position: "relative",
    "&:not(:hover):after": {
      width: "56px",
      height: "56px",
      boxSizing: "border-box",
      content: '" "',
      display: "block",
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      pointerEvents: "none",
      border: `6px solid ${theme.palette.primary.main}`,
      borderRadius: "50%",
      animationDuration: "1.2s",
      animationName: "$intervalHamburgerBorder",
      animationIterationCount: "infinite",
    },
  },
  link: {
    color: `${theme.palette.primary.main}!important`,
    display: "flex",
  },
  tooltip: {
    width: "160px",
  },
  tooltipTitle: {
    color: "black",
  },
  wrapper: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 999,
  },
  "@keyframes intervalHamburgerBorder": {
    "0%": {
      opacity: 1,
      transform: "scale(1)",
    },
    "80%": {
      transform: "scale(1.6)",
    },
    "100%": {
      opacity: 0,
      transform: "scale(1.6)",
    },
  },
}))

export default function ContactFab() {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const contactDetails = useContactContent()
  const { whatsappAppicon } = useImagesContent()

  const whatsappIcon = (
    <div style={{ display: "flex" }}>
      <Img
        style={{ width: "25px", height: "25px" }}
        fixed={whatsappAppicon}
        alt={"Whatsapp icon"}
      />
    </div>
  )

  const actions = [
    {
      icon: whatsappIcon,
      name: "WhatsApp (EN)",
      link: `https://wa.me/${contactDetails.whatsapp_english}`,
    },

    {
      icon: whatsappIcon,
      name: "WhatsApp (TH)",
      link: `https://wa.me/${contactDetails.whatsapp_thai}`,
    },
    {
      icon: <EmailOutlined />,
      name: "Email us",
      link: `mailto:${contactDetails.email}`,
    },
    {
      icon: <Phone />,
      name: "Call us (EN)",
      link: `tel:${contactDetails.phone_english}`,
    },
    {
      icon: <Phone />,
      name: "Call us (TH)",
      link: `tel:${contactDetails.phone_thai}`,
    },
  ]

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = (e?: any, reason?: any) => {
    if (reason !== "mouseLeave") {
      setOpen(false)
    }
  }

  return (
    <div className={classes.wrapper + " contact-fab"}>
      <SpeedDial
        ariaLabel="Contact button"
        className={classes.speedDial}
        icon={<ChatBubbleOutline />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            title={action.name}
            icon={
              <a href={action.link} className={classes.link}>
                {action.icon}
              </a>
            }
            tooltipTitle={
              <a className={classes.tooltipTitle} href={action.link}>
                {action.name}
              </a>
            }
            tooltipOpen
            onClick={handleClose}
            style={{ display: "flex", alignItems: "center" }}
          />
        ))}
      </SpeedDial>
    </div>
  )
}
