import React from "react"

import Header from "../components/header"
import theme from "../theme"
import { ThemeProvider, useMediaQuery, CssBaseline } from "@material-ui/core"
import Footer from "../components/footer"
import ContactFab from "../components/contact-fab"
import ScrollTopButton from "../components/scroll-top-button"

export default function Layout({ children, pageContext }) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)")

  const selectedTheme = React.useMemo(
    () => theme(prefersDarkMode ? "dark" : "light"),
    [prefersDarkMode]
  )

  return (
    <ThemeProvider theme={selectedTheme}>
      <CssBaseline />
      <Header hasHero={pageContext.hasHero} />
      <main>{children}</main>
      <Footer></Footer>
      <ContactFab />
      <ScrollTopButton />
    </ThemeProvider>
  )
}
