import { graphql, useStaticQuery } from "gatsby"
import { FixedObject } from "gatsby-image"

export default function useImagesContent() {
  const {
    whatsappAppicon: {
      childImageSharp: {
        fixed: whatsappAppiconImage
      },
    },
  } = useStaticQuery(graphql`
    query {
      whatsappAppicon: file(sourceInstanceName: {eq: "images"}, name: {eq: "whatsapp-appicon"}) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  return {
    whatsappAppicon: whatsappAppiconImage as FixedObject,
  }
}
