import { graphql, useStaticQuery } from "gatsby"
import { FixedObject } from "gatsby-image"

export default function useConfig() {
  const {
    file: {
      config: {
        frontmatter: { copyright, logo },
      },
    },
  } = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "data" }, name: { eq: "config" }) {
        config: childMarkdownRemark {
          frontmatter {
            copyright
            logo {
              childImageSharp {
                fixed(width: 120) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    }
  `)
  return {
    copyright,
    logo: logo?.childImageSharp?.fixed as FixedObject,
  }
}
