import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles"

// A custom theme for this app
const theme = mode =>
  responsiveFontSizes(
    createMuiTheme({
      typography: {
        fontFamily: "Montserrat",
        h1: {
          fontSize: "5rem",
        },
      },
      palette: {
        primary: {
          main: "#7e6240",
        },
      },
    })
  )

export default theme
