import { graphql, useStaticQuery } from "gatsby"

export default function useContactContent() {
  const {
    file: {
      childMarkdownRemark: {
        html,
        frontmatter: {
          title,
          email,
          whatsapp_english,
          whatsapp_thai,
          phone_english,
          phone_thai,
          youtube_label,
          youtube_url,
          instagram_label,
          instagram_url,
          vimeo_label,
          vimeo_url,
          facebook_label,
          facebook_url,
          address,
        },
      },
    },
  } = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "data" }, name: { eq: "contact" }) {
        childMarkdownRemark {
          html
          frontmatter {
            title
            email
            whatsapp_english
            whatsapp_thai
            phone_english
            phone_thai
            youtube_label
            youtube_url
            instagram_label
            instagram_url
            vimeo_label
            vimeo_url
            facebook_label
            facebook_url
            address
          }
        }
      }
    }
  `)
  return {
    title,
    html,
    email,
    whatsapp_english,
    whatsapp_thai,
    phone_english,
    phone_thai,
    youtube_label,
    youtube_url,
    instagram_label,
    instagram_url,
    vimeo_label,
    vimeo_url,
    facebook_label,
    facebook_url,
    address,
  }
}
