import Img from "gatsby-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import React, { useState, useEffect } from "react"
import {
  AppBar,
  Toolbar,
  Theme,
  Box,
  Container,
  ListItem,
  List,
  ListItemText,
  Drawer,
  IconButton,
  Divider,
  Hidden,
} from "@material-ui/core"
import { makeStyles, createStyles } from "@material-ui/styles"
import { ChevronRight, Menu } from "@material-ui/icons"
import useConfig from "../hooks/use-config"

const menuItems = [
  {
    label: "HOME",
    to: "/",
  },
  {
    label: "WHY US",
    to: "/#why",
  },
  {
    label: "WHO WE ARE",
    to: "/#who",
  },
  {
    label: "WHAT WE DO",
    to: "/#what",
  },
  {
    label: "HOW WE DO IT",
    to: "/#how",
  },
  {
    label: "PHOTOS",
    to: "/#photos",
  },
  {
    label: "VIDEOS",
    to: "/#videos",
  },
  {
    label: "CLIENTS",
    to: "/#clients",
  },
  {
    label: "BLOG",
    to: "/#work",
  },
  {
    label: "CONTACT",
    to: "/#contact",
  },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: ({ isScrolled }: { isScrolled: boolean }) => ({
      boxShadow: isScrolled ? theme.shadows[6] : theme.shadows[0],
      backgroundColor: isScrolled ? "#fff" : "transparent",
      transition: ".5s",
      marginBottom: "25px",
    }),
    navigationWrapper: ({
      isScrolled,
      hasHero,
    }: {
      isScrolled: boolean
      hasHero: boolean
    }) => ({
      display: "flex",
      background: !isScrolled && hasHero ? "#00000091" : "none",
    }),
    toolbar: {
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    link: ({
      isScrolled,
      hasHero,
    }: {
      isScrolled: boolean
      hasHero: boolean
    }) => ({
      fontFamily: "'Open Sans Condensed', sans-serif",
      padding: 15,
      color: !isScrolled && hasHero ? "#fff" : "#000",
      textDecoration: "none",
      fontWeight: 300,
      fontSize: "1rem",
    }),
    sidenavLink: {
      color: "black",
    },
    active: {
      color: theme.palette.primary.main,
      fontWeight: 300,
      display: "block",
    },
    logoImg: {
      height: "100px",
      [theme.breakpoints.down("sm")]: {
        height: "50px",
      },
    },
  })
)

export interface HeaderData {
  hasHero: boolean
}

export default function Header({ hasHero }: HeaderData) {
  const [isScrolled, setIsScrolled] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const classes = useStyles({ isScrolled, hasHero })
  const { logo } = useConfig()

  useEffect(() => {
    const handleScroll = e => {
      if (isScrolled && document.scrollingElement.scrollTop == 0) {
        setIsScrolled(false)
      } else if (!isScrolled && document.scrollingElement.scrollTop >= 80) {
        setIsScrolled(true)
      }
    }

    document.addEventListener("scroll", handleScroll)
    return () => document.removeEventListener("scroll", handleScroll)
  }, [isScrolled])

  let appbarPosition = "fixed"
  if (!isScrolled) {
    appbarPosition = hasHero ? "absolute" : "relative"
  }

  const isPartiallyActive = ({ isPartiallyCurrent, location, href }) => {
    const isBlogCategory =
      href === "/blog" && location.pathname.startsWith("/category")
    return isBlogCategory || isPartiallyCurrent
      ? { className: `${classes.active} ${classes.link}` }
      : null
  }

  const drawerList = (
    <>
      <div>
        <IconButton onClick={_ => toggleDrawer(false)} title="Close sidenav">
          <ChevronRight />
        </IconButton>
      </div>
      <Divider />
      <List>
        {menuItems.map(item => (
          <ListItem button key={item.label}>
            <AnchorLink
              to={item.to}
              onAnchorLinkClick={() => toggleDrawer(false)}
              gatsbyLinkProps={{
                className: classes.sidenavLink,
                activeClassName: classes.active,
                getProps: item.to === "/" ? undefined : isPartiallyActive,
              }}
            >
              <ListItemText className="font-mb-menu" primary={item.label} />
            </AnchorLink>
          </ListItem>
        ))}
      </List>
    </>
  )

  const toggleDrawer = open => {
    setDrawerOpen(open)
  }

  return (
    <AppBar position={appbarPosition as any} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Container>
          <Box
            display="flex"
            flexDirection={isScrolled ? "row" : "column"}
            alignItems="center"
            justifyContent={isScrolled ? "space-between" : "center"}
          >
            <Box
              mt={isScrolled ? 0 : 3}
              mb={isScrolled ? 0 : 1}
              className={classes.logoImg}
            >
              <AnchorLink to="/" title={"Go to homepage"}>
                <Img
                  fixed={logo}
                  alt={"Logo"}
                  imgStyle={{
                    objectFit: "contain",
                  }}
                  style={{ height: "100%" }}
                />
              </AnchorLink>
            </Box>
            <Hidden smDown implementation="css">
              <div className={classes.navigationWrapper}>
                {menuItems.map(item => (
                  <AnchorLink
                    key={"mainnav-" + item.label}
                    className={classes.link}
                    to={item.to}
                    stripHash={false}
                    gatsbyLinkProps={{
                      activeClassName: classes.active,
                      getProps: item.to === "/" ? undefined : isPartiallyActive,
                    }}
                  >
                    {item.label}
                  </AnchorLink>
                ))}
              </div>
            </Hidden>
            <Hidden mdUp implementation="css">
              <IconButton
                onClick={_ => toggleDrawer(true)}
                title="Open sidenav"
              >
                <Menu />
              </IconButton>
            </Hidden>
          </Box>
        </Container>
      </Toolbar>
      <Drawer
        anchor="right"
        open={drawerOpen}
        variant="temporary"
        onClose={_ => toggleDrawer(false)}
      >
        {drawerList}
      </Drawer>
    </AppBar>
  )
}
