import {
  Button,
  Container,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Box,
} from "@material-ui/core"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEnvelope,
  faPhoneAlt,
  IconDefinition,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons"
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import useContactContent from "../hooks/use-contact-content"
import useConfig from "../hooks/use-config"
import Flag from "./flag"

const useStylesContact = makeStyles((theme: Theme) => ({
  link: {
    color: "#fff",
    justifyContent: "left",
  },
  linkIcon: {
    marginTop: "10px",
  },
}))

interface MultiContactProps {
  enText: string
  enLink: string
  thText: string
  thLink: string
  icon: IconDefinition
}

const MultiContact = (props: MultiContactProps) => {
  const classes = useStylesContact()

  return (
    <div style={{ display: "inline-flex" }}>
      <FontAwesomeIcon
        size="1x"
        icon={props.icon}
        className={classes.linkIcon}
      />
      <div style={{ display: "inline-flex", flexDirection: "column" }}>
        <Button href={props.enLink} className={classes.link}>
          <Flag size={20} country="uk" />{" "}
          <span style={{ marginLeft: "5px" }}>{props.enText}</span>
        </Button>
        <Button href={props.thLink} className={classes.link}>
          <Flag size={20} country="th" />{" "}
          <span style={{ marginLeft: "5px" }}>{props.thText}</span>
        </Button>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(12),
    backgroundColor: "#000",
    color: "#fff",
    position: "relative",
    "&:after": {
      position: "absolute",
      content: '""',
      width: "100%",
      height: "100%",
      top: 0,
      left: 0,
      backgroundColor: "black",
      transformOrigin: "top right",
      transform: "skewY(3deg)",
      zIndex: 1,
    },
  },
  footerInner: {
    zIndex: 2,
    position: "relative",
  },
  link: {
    color: "#fff",
  },
  copyright: {
    marginTop: theme.spacing(3),
    textAlign: "center",
    opacity: ".6",
  },
  linkSeparator: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  pageLinks: {
    textAlign: "right",
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down(`md`)]: {
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      marginBottom: "30px;",
    },
  },
  leftHalf: {
    order: 2,
    [theme.breakpoints.up(`md`)]: {
      order: 1,
    },
  },
  rightHalf: {
    order: 1,
    [theme.breakpoints.up(`md`)]: {
      order: 2,
    },
  },
}))

export default function Footer() {
  const classes = useStyles()
  const contactDetails = useContactContent()
  const { copyright } = useConfig()

  return (
    <footer className={classes.footer}>
      <Container className={classes.footerInner}>
        <Grid container>
          <Grid item md={8} className={classes.leftHalf}>
            <Typography component={"div"} variant="body1">
              <Box display="flex" flexWrap="wrap">
                <Box mx={1} width="100%" mb={1}>
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  <span style={{ marginLeft: "10px" }}>
                    {contactDetails.address}
                  </span>
                </Box>
                <Box mx={1}>
                  <FontAwesomeIcon size="1x" icon={faEnvelope} />
                  <Button
                    href={`mailto:${contactDetails.email}`}
                    className={classes.link}
                  >
                    {contactDetails.email}
                  </Button>
                </Box>
                <Box mx={1}>
                  <MultiContact
                    enText={`${contactDetails.phone_english}`}
                    enLink={`tel:${contactDetails.phone_english}`}
                    thText={`${contactDetails.phone_thai}`}
                    thLink={`tel:${contactDetails.phone_thai}`}
                    icon={faPhoneAlt}
                  />
                </Box>
                <Box mx={1}>
                  <MultiContact
                    enText={`${contactDetails.whatsapp_english}`}
                    enLink={`https://wa.me/${contactDetails.whatsapp_english}`}
                    thText={`${contactDetails.whatsapp_thai}`}
                    thLink={`https://wa.me/${contactDetails.whatsapp_thai}`}
                    icon={faWhatsapp}
                  />
                </Box>
              </Box>
            </Typography>
          </Grid>
          <Grid
            item
            md={4}
            className={classes.pageLinks + " " + classes.rightHalf}
          >
            <AnchorLink to="/#why" className={classes.link}>
              Why Us
            </AnchorLink>
            <span className={classes.linkSeparator}>•</span>
            <AnchorLink to="/#what" className={classes.link}>
              What we do
            </AnchorLink>
            <span className={classes.linkSeparator}>•</span>
            <AnchorLink to="/#who" className={classes.link}>
              Who we are
            </AnchorLink>
            <span className={classes.linkSeparator}>•</span>
            <AnchorLink to="/#how" className={classes.link}>
              How we do it
            </AnchorLink>
            <span className={classes.linkSeparator}>•</span>
            <AnchorLink to="/#clients" className={classes.link}>
              Our clients
            </AnchorLink>
            <span className={classes.linkSeparator}>•</span>
            <AnchorLink to="/#photos" className={classes.link}>
              Photos
            </AnchorLink>
            <span className={classes.linkSeparator}>•</span>
            <AnchorLink to="/#videos" className={classes.link}>
              Videos
            </AnchorLink>
            <span className={classes.linkSeparator}>•</span>
            <AnchorLink to="/#work" className={classes.link}>
              Latest Work
            </AnchorLink>
            <span className={classes.linkSeparator}>•</span>
            <Link to="/privacy-policy" className={classes.link}>
              Privacy policy
            </Link>
          </Grid>
        </Grid>
        <Typography className={classes.copyright}>
          Copyright {new Date().getFullYear()} {copyright}
        </Typography>
      </Container>
    </footer>
  )
}
