import React from "react"
import {
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: (size: number) => ({
      width: `${size}px`,
      height: `${size}px`,
			borderRadius: "100%",
			overflow: "hidden"
    }),
    image: {
			width: "100%",
			height: "100%",
			objectFit: "cover"
    },
  })
)

export interface FlagProps {
	country: "uk" | "th",
	size?: number
}

export default function Flag(data: FlagProps) {
  const classes = useStyles(data.size || 30)

  return (
    <div className={classes.wrapper}>
      <img className={classes.image} src={`/images/flag-${data.country}.svg`} alt={`${data.country} flag`} />
    </div>
  )
}
