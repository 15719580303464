import { Fab, makeStyles, Theme } from "@material-ui/core"
import { ArrowUpward } from "@material-ui/icons"
import React, { useEffect, useState } from "react"

const useStyles = makeStyles((theme: Theme) => ({
  scrollTopBtn: (isScrolled: boolean) => ({
    bottom: "50px",
    position: "fixed",
    left: "50%",
		transform: "translateX(-50%)",
		display: isScrolled ? "" : "none",
		zIndex: 999999
  }),
}))

export default function ScrollTopButton() {
	const [isScrolled, setIsScrolled] = useState(false)
	const classes = useStyles(isScrolled)

	let scrollListener = null
  useEffect(() => {
    scrollListener = document.addEventListener("scroll", e => {
      const _isScrolled = document.scrollingElement.scrollTop >= 200
      if (_isScrolled !== isScrolled) setIsScrolled(_isScrolled)
    })
    return () => {
      document.removeEventListener("scroll", scrollListener)
    }
  }, [isScrolled])

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
	}
	
  return (
    <Fab
      aria-label="go to top"
      size="small"
      className={classes.scrollTopBtn}
      onClick={scrollToTop}
    >
      <ArrowUpward />
    </Fab>
  )
}
